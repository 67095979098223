import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import { withPage } from "../PageContainer";
import * as Ant from "antd";
const validator = require("validator");

const UiState = {
  init: 0,
  registering: 1,
  registered: 2,
};

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uiState: UiState.init,
      values: {
        username: "",
        email: "",
        password: "",
        password2: "",
      },
      validation: "",
      loading: false,
    };
  }

  render() {
    const { uiState, values, validation, loading } = this.state;

    return (
      <Wrapper>
        <div className="center-content">
          {(uiState === UiState.registered && (
            <>
              <h2>註冊成功!</h2>
              <p>請於兩分鐘內至此信箱取得驗證碼: {values.email}</p>
              <div>
                <label>驗證碼</label>
                <Ant.Input
                  value={validation}
                  onChange={e => this.setState({ validation: e.target.value })}
                />
              </div>
              <BottomBar>
                <Ant.Button
                  type="primary"
                  loading={loading}
                  onClick={this._handleValidate}
                >
                  驗證
                </Ant.Button>
              </BottomBar>
            </>
          )) || (
            <>
              <h2>註冊新帳號</h2>

              <div>
                <label htmlFor="login-username-input">Email</label>
                <Ant.Input
                  id="register-email-input"
                  value={values.email}
                  onChange={e =>
                    this.setState({
                      values: { ...values, email: e.target.value },
                    })
                  }
                />
              </div>

              <div>
                <label htmlFor="register-password-input">密碼</label>
                <Ant.Input
                  id="login-password-input"
                  type="password"
                  value={values.password}
                  onChange={e =>
                    this.setState({
                      values: { ...values, password: e.target.value },
                    })
                  }
                />
              </div>

              <div>
                <label htmlFor="register-password-input">確認密碼</label>
                <Ant.Input
                  id="login-password-input-confirm"
                  type="password"
                  value={values.password2}
                  onChange={e =>
                    this.setState({
                      values: { ...values, password2: e.target.value },
                    })
                  }
                />
              </div>

              <BottomBar>
                <Ant.Button
                  type="primary"
                  loading={uiState === UiState.registering}
                  onClick={this._handleRegister}
                >
                  註冊
                </Ant.Button>
              </BottomBar>
            </>
          )}
        </div>
      </Wrapper>
    );
  }

  _handleRegister = async () => {
    const { appActions } = this.props;
    const { values } = this.state;

    if (!values.email || !values.password || !values.password2) {
      return;
    }

    if (!validator.isEmail(values.email)) {
      alert("Email format error!");
      return;
    }

    if (values.password !== values.password2) {
      alert("Password doesn't match!");
      return;
    }

    try {
      this.setState({ uiState: UiState.registering });

      await appActions.register({
        email: values.email,
        password: values.password,
      });

      this.setState({ uiState: UiState.registered });
    } catch (ex) {
      console.warn(ex);
      alert("Register API fail, please retry!");
      this.setState({ uiState: UiState.init });
      return;
    }
  };

  _handleValidate = async () => {
    let { validation, values } = this.state;
    let { appActions, navActions } = this.props;
    this.setState({ loading: true });
    try {
      let resp = await appActions.register_validation({
        validation_code: validation,
        password: values.password,
      });
      await appActions.autoLogin();
      navActions.push("/profile");
    } catch (ex) {
      console.warn(ex);
    }
    this.setState({ loading: false });
  };
}

const Wrapper = styled.div`
  padding: 20px;
  background-color: white;

  .center-content {
    max-width: 600px;
    margin: 20px auto;
  }
`;

const BottomBar = styled.div`
  margin-top: 10px;
  display: flex;
`;

export default withPage(connect(null, ActionCreator)(RegisterPage));
